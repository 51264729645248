.downloads-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px;
  }
  
  .download-item {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 200px; /* Adjust as needed */
    text-align: center;
    padding: 15px;
  }
  
  .download-image {
    width: 100%;
    height: auto;
    border-radius: 5px;
  }
  
  .download-info h3 {
    margin: 10px 0;
  }
  
  .download-info button {
    background-color: #f57c00; /* Orange */
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .download-info button:hover {
    background-color: #e67300; /* A darker shade of orange */
  }
  
  /* Responsive adjustments for screens smaller than 600px */
  @media (max-width: 600px) {
    .download-item {
        width: 100%; /* Full width for smaller screens */
        padding: 10px;
    }

    .download-image {
        width: 80%; /* Smaller image width for mobile */
        height: auto; /* Maintain aspect ratio */
        margin: 0 auto; /* Center the image */
    }

    .download-info h3 {
        font-size: 1em; /* Smaller font size */
    }

    .download-info button {
        padding: 8px 15px; /* Smaller buttons for mobile */
        font-size: 0.9em; /* Smaller font size for button text */
    }
}
  