/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css?family=Roboto:400,700');
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');

/* Body and General Styles */
body {
  font-family: 'Roboto', sans-serif;
  color: #333;
  background-color: #f3e5f5; /* Light purple background */
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
}

/* Header and Navigation Bar */
.App-header {
  background-color: #4a148c; /* Dark purple */
  color: white;
  min-height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.App-logo {
  height: 60px; /* Adjust the logo size */
  width: auto;
}

nav {
  display: flex;
  justify-content: center;
  align-items: center;
}

nav a {
  color: white;
  text-decoration: none;
  margin: 0 20px;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

nav a:hover {
  background-color: #f57c00; /* Orange for hover state */
  color: white;
}

/* Form Container and Box */
.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-top: 30px;
}

.form-box {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.form-box label {
  display: block;
  margin-bottom: 10px;
}

.form-box input[type="text"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Button Style */
button {
  background-color: #f57c00; /* Orange */
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  width: 100%;
}

button:hover {
  background-color: #e67300; /* A darker shade of orange */
}

/* Responsive adjustments for screens smaller than 600px */
@media (max-width: 600px) {
  .App-header {
      flex-direction: row; /* Horizontal layout */
      justify-content: space-between; /* Space out logo and nav */
      padding: 10px; /* Reduced padding */
  }

  .App-logo {
      height: 40px; /* Smaller logo */
  }

  nav {
      flex-direction: row; /* Horizontal layout for nav links */
  }

  nav a {
      font-size: 0.8em; /* Smaller font size for nav links */
      padding: 5px 8px; /* Smaller padding for nav links */
      margin: 0 5px; /* Reduced margin between links */
  }
}


/* Add global styles for the corners */
body {
    position: relative;
}

.corner-decoration {
    position: absolute;
    width: 100px; /* Adjust the size as needed */
    height: auto;
    z-index: 1000; /* Ensure the images are above other elements */
}

.corner-top-left {
    top: 0;
    left: 0;
    transform: rotate(0deg); /* No rotation for the top left */
}

.corner-top-right {
    top: 0;
    right: 0;
    transform: rotate(90deg); /* Rotate for the top right */
}

.corner-bottom-left {
    bottom: 0;
    left: 0;
    transform: rotate(270deg); /* Rotate for the bottom left */
}

.corner-bottom-right {
    bottom: 0;
    right: 0;
    transform: rotate(180deg); /* Rotate for the bottom right */
}
