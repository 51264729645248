/* Basic reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Serif', sans-serif; /* Placeholder for modern yet classic font */
    background-color: #2c534e; /* Deep blue background */
    color: #F2A365; /* Gold color text */
    line-height: 1.6;
}

.container {
    position: relative;
    min-height: 100vh;
    width: 80%;
    margin: auto;
    overflow: hidden;
}

header {
    background: #8a1043;
    color: #F2A365;
    height: 70px; /* Set a fixed height for the navigation bar */
    border-bottom: #F2A365 3px solid;
}

header a {
    color: #F2A365;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 16px;
}

header ul {
    padding: 0;
    list-style: none;
    text-align: center;
    /* Updated to make the navigation bar vertical on phones */
    flex-direction: column;
}

header li {
    display: inline;
    margin: 0 15px;
}

header .logo {
    float: left;
    margin-top: 0px;
}

header .logo img {
    height: 50px; /* Increase logo size for mobile devices */
}

header nav {
    float: right;
    margin-top: 10px;
}

/* Main area styling */
.main {
    text-align: center;
    padding: 50px 20px;
}

.main h1 {
    font-size: 3em;
    margin-bottom: 20px;
    color: #F2A365;
}

.main p {
    font-size: 1.2em;
}

.main .btn {
    font-size: 1.8em;
    background: #F2A365;
    color: #0A122A;
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 5px;
    margin-top: 20px;
}

.main .btn:hover {
    background: #e09d3e;
}

/* Footer styling */
footer {
    background: #8a1043;
    color: #F2A365;
    text-align: center;
    padding: 30px 20px;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50px;
}

footer p {
    margin: 10px 0;
}

/* Add responsiveness */
@media (max-width: 768px) {
    header .logo,
    header nav {
        float: none;
        text-align: center;
        margin-top: 10px;
    }
    
    header .logo img {
        display: inline-block;
        height: 40px; /* Reduce logo size for mobile devices */
    }

    header nav {
        margin-top: 5px;
    }
    
    .container {
        width: 95%;
    }
    
    /* Adjust the navigation bar for mobile */
    header ul {
        text-align: center;
        /* Make the navigation bar vertical on phones */
        flex-direction: row;
    }
    
    header li {
        display: block;
        margin: 10px 0;
    }
}

/* Full width header and footer */
header, footer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Remove the max-width and padding from the container class to allow full width */
.container {
    max-width: none;
    width: 100%;
    padding: 0;
}

/* Add padding inside main content instead of container */
.main {
    padding: 0 20px;
}

/* Enlarge and position the logo to the left */
.logo {
    position: absolute;
    left: 0px; /* Distance from the left */
    top: 0px; /* Distance from the top */
}

.logo img {
    height: 100px; /* Increase logo size */
}

/* Corner decoration styles */
.corner-decoration {
    position: absolute;
    width: 90px; /* Adjust the width and height as needed */
    height: 90px;
}

.corner-top-right {
    top: 0;
    right: 0;
    transform: rotate(90deg);
}

.corner-bottom-left {
    bottom: 0;
    left: 0;
    transform: rotate(270deg);
    position: fixed; /* Make it fixed */
    z-index: 1; /* Set the z-index to ensure it stays above other content */
}
.menu-button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 30px;
    height: 30px;
    margin: 10px;
    padding: 0;
  }
  
  .menu-icon {
    width: 30px;
    height: 3px;
    background-color: #fff;
    margin: 3px 0;
    transition: all 0.3s ease-in-out;
  }
  
  .menu-button.open .menu-icon:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }
  
  .menu-button.open .menu-icon:nth-child(2) {
    opacity: 0;
  }
  
  .menu-button.open .menu-icon:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
  .menu-box {
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 60px; /* Adjust the positioning */
    left: 0;
    right: 0;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.3s ease-in-out;
  }
  
  .menu-box ul {
    list-style: none;
    padding: 0;
  }
  
  .menu-box li {
    margin: 10px 0;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
  }
  
  .menu-box li:hover {
    color: #F2A365;
  }